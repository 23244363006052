import React, { useState } from 'react';
import '../CSS/Contacts.css'
import { BsEnvelope, BsPhone, BsGeoAlt } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { SiLinkedin } from "react-icons/si";
import { FaFacebookF } from "react-icons/fa";



const Contacts = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    description: '',
  });
  const [submissionStatus, setSubmissionStatus] = useState(null); // Tracks submission state

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const portalId = '47876289';
    const formId = '8a1550c7-ec56-437f-b43c-abaf7e6e5052';
    const endpoint = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

    const payload = {
      fields: [
        { name: 'firstname', value: formData.firstName },
        { name: 'lastname', value: formData.lastName },
        { name: 'email', value: formData.email },
        { name: 'phone', value: formData.phone },
        { name: 'description', value: formData.description },
      ],
    };

    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setSubmissionStatus('success');
        setFormData({ firstName: '', lastName: '', email: '', phone: '', description: '' }); // Clear the form
        console.log('Form submitted successfully');
      } else {
        setSubmissionStatus('error');
        console.error('Form submission error', response.status);
      }
    } catch (error) {
      setSubmissionStatus('error');
      console.error('Error submitting form:', error);
    }
  };

  return (
    <>
    <section className='form'>
      <div className='form-block'>
        <div className='form-text'>
          <h2>Get Started <span style={{ color:'#ff4400' }}>Today</span> By Filling In Your 
          Details</h2>
          <div className='contact-info'>
            <p><BsEnvelope />kms@mocaby.co.za</p>
            <p><BsPhone /> +27-64-801-6144</p>
            <p><BsGeoAlt /> 173 Oxford Road, Rosebank, Gauteng, 2196</p>

          <div>
            <div className='socials'>
              <FaFacebookF />
              <SiLinkedin />
              <BsTwitter/>
            </div>
              
        </div>
      </div>
      </div>
      <form className='form-group' onSubmit={handleSubmit}>
        <div className='feedback'>
          {submissionStatus === 'success' && <p className="success-message">Thank you! Your form has been submitted successfully.</p>}
          {submissionStatus === 'error' && <p className="error-message">Oops! There was an issue submitting the form. Please try again.</p>}
        </div>
        <div className='form-head'>
          <label htmlFor='firstName' className='form-label'>First Name</label>
          <div className="input-wrapper">
            <input type="text" id="firstName" name="firstName" className="form-control" placeholder="Name" value={formData.firstName} onChange={handleChange} />
          </div> 

          <label htmlFor='lastName' className='form-label'>Last Name:</label>
          <div className="input-wrapper">
            <input type="text" id="lastName" name="lastName" className="form-control" placeholder="Surname" value={formData.lastName} onChange={handleChange} />
          </div> 

          <label htmlFor='email' className='form-label'>Email</label>
          <div className="input-wrapper">
            <input type="email" id="email" name="email" className="form-control" placeholder="example@gmail.com" value={formData.email} onChange={handleChange} />
          </div>

          <label htmlFor='phone' className='form-label'>Phone</label>
          <div className="input-wrapper">
            <input type="tel" id="phone" name="phone" className="form-control" placeholder="+XX-XX-XXX-XXXX" value={formData.phone} onChange={handleChange} />
          </div>

          <textarea rows={6} name="description" placeholder="Description (optional)" value={formData.description} onChange={handleChange} />

          <button type='submit' className='submit'>Submit</button>          
        </div>
      </form>


      </div>
    </section>
    </>
  )
}

export default Contacts