import React from 'react'
import Navbar from '../Components/Navbar'
import Page2 from '../Components/Page2'

const AboutUs = () => {
  return (
    <>
    <Navbar />
    <Page2 />
    </>
  )
}

export default AboutUs